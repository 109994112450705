.main {
  width: 100dvw;
  height: 100dvh;
  display: flex;
  flex-grow: 1;
}

.pic {
  width: 50%;
  height: 100dvh;
}

.form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  width: 50%;
}

.picture {
  height: 100dvh;
  width: 100%;
}

.formcontent {
  width: 60%;
}

.loginFooter {
  color: black;
  display: flex;
  width: 100%;
  justify-content: center;
  text-decoration: none;
}

.loginFooterLink {
  text-decoration: none;
  color: steelblue;
  text-decoration: none;
}

.loginFooterLink:hover {
  color: #69c37f;
}
