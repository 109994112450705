.centerLogo {
  height: 20dvh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.centerImage {
  height: 100px;
  width: 100px;
}
