.card-shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.card-shadow:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.bg-primary-gradient {
  background: linear-gradient(135deg, #007bff, #0056b3);
}

.bg-info-gradient {
  background: linear-gradient(135deg, #17a2b8, #117a8b);
}

.bg-success-gradient {
  background: linear-gradient(135deg, #28a745, #1e7e34);
}

.bg-warning-gradient {
  background: linear-gradient(135deg, #ffc107, #e0a800);
}

.bg-light-gradient {
  background: linear-gradient(135deg, #f8f9fa, #e9ecef);
}

.table-primary-gradient {
  background: linear-gradient(135deg, #cce5ff, #99ccff);
}

.table-danger-gradient {
  background: linear-gradient(135deg, #f8d7da, #f5c6cb);
}

.hover-focus:hover {
  /* background: linear-gradient(135deg, #ffffff, #f0f0f0); */
  box-shadow: 0 10px 16px rgba(0, 0, 0, 0.2);
  scale: 1.01;
}

a {
  text-decoration: none !important;
}
