.dashboardContents {
  display: flex;
  padding: 10px;
  justify-content: space-between;
}

.leftDashboardContents {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}

.rightDashboardContents {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}

.welcome {
  display: flex;
  gap: 0px;
  padding: 10px;
  align-items: center;
  border-radius: 10px;
  box-shadow: 1px 1px 7px gray;
}

.title {
  color: rgb(105, 195, 123);
  font-weight: 700;
  font-size: 20px;
  font-family: "poppins";
}

.welcomeText {
  font-size: 14px;
}

.eventsBox {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 30px;
}

.eventTitleCalendar {
  display: flex;
  align-items: start;
}
