.logout,
.notifications,
.profile {
  text-decoration: none;
  color: black;
}
.logout:hover,
.notifications:hover,
.profile:hover {
  color: green;
}
