* {
  box-sizing: border-box;
  margin: 0px;
  font-family: "roboto";
  padding: 0px;
  font-weight: 300;
}
.leftmenu {
  display: flex;
  gap: 10px;
}

.rightmenu {
  display: flex;
  text-align: end;
}

.side {
  background-color: chartreuse;
}

.dashboardContents {
  display: flex;
  padding: 10px;
  justify-content: space-between;
}

.leftDashboardContents {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}

.rightDashboardContents {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}

.welcome {
  display: flex;
  gap: 0px;
  padding: 10px;
  align-items: center;
  border-radius: 10px;
}

.title {
  color: rgb(105, 195, 123);
  font-weight: 700;
  font-size: 20px;
  font-family: "poppins";
}

.welcomeText {
  font-size: 14px;
}

.eventsBox {
  display: flex;
}

.owner {
  text-decoration: none;
  color: #305938;
}
.linkNoDecoration {
  color: #37623e;
  text-decoration: none;
}

.linkNoDecoration:hover {
  color: white;
}
